import styles from './ComponentBannerCarousel.module.scss';

import { Carousel, CarouselSlide } from '@/ui/base';

import { ComponentCalloutHero } from '@/ui/components/ComponentHero'

import { BannerCarouselComponent, CalloutHero } from '@/types';

const ComponentBannerCarousel = ({
  sys,
  carouselHeading,
  slideXLabel,
  slideXCountLabel,
  nextLabel,
  previousLabel,
  slides,
}: BannerCarouselComponent) => {
  return (
    <div className={styles.bannerCarousel}>
      <h2 className={styles.bannerCarousel__heading}>
        {carouselHeading}
      </h2>
      <Carousel
        id={sys?.id ?? 'banner-carousel'}
        className={styles.bannerCarousel__inner}
        loadedClass={styles.loaded}
        accessibleTitle={carouselHeading}
        slideCountLabel={slideXCountLabel}
        slideLabel={slideXLabel}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        autoplay
      >
        {slides.map((item: CalloutHero, index: number) => {
          return (
            <CarouselSlide
              key={`${sys?.id}-${index}`}
              className={styles.bannerCarousel__slide}
            >
              <ComponentCalloutHero {...item} isCarouselSlide />
            </CarouselSlide>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ComponentBannerCarousel;
